var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Floating Label Inputs"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeFloatinLabel) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-2"
  }, [_c('span', [_vm._v("For Flating Label Inputs, need to use ")]), _c('code', [_vm._v(".form-label-group")]), _c('span', [_vm._v(" class & add prop ")]), _c('code', [_vm._v("disabled")]), _c('span', [_vm._v(" for disabled Floating Label Input.")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "form-label-group"
  }, [_c('b-form-input', {
    attrs: {
      "id": "floating-label1",
      "placeholder": "Label Placeholder"
    }
  }), _c('label', {
    attrs: {
      "for": "floating-label1"
    }
  }, [_vm._v("Label-placeholder")])], 1)]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "form-label-group"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Disabled-placeholder",
      "disabled": ""
    }
  }), _c('label', {
    attrs: {
      "for": "floating-label-disable"
    }
  }, [_vm._v("Disabled-placeholder")])], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }