var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Formatter support"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeFormatter) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('code', [_vm._v("<b-form-input>")]), _c('span', [_vm._v(" optionally supports formatting by passing a function reference to the ")]), _c('code', [_vm._v("formatter")]), _c('span', [_vm._v(" prop.")])]), _c('div', [_c('b-form-group', {
    attrs: {
      "label": "Text input with formatter (on input)",
      "label-for": "input-formatter",
      "description": "We will convert your name to lowercase instantly"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-formatter",
      "placeholder": "Enter your name",
      "formatter": _vm.formatter
    },
    model: {
      value: _vm.text1,
      callback: function callback($$v) {
        _vm.text1 = $$v;
      },
      expression: "text1"
    }
  })], 1), _c('b-card-text', [_c('strong', [_vm._v("Value:")]), _vm._v(" " + _vm._s(_vm.text1))]), _c('b-form-group', {
    attrs: {
      "label": "Text input with lazy formatter (on blur)",
      "label-for": "input-lazy",
      "description": "This one is a little lazy!"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-lazy",
      "placeholder": "Enter your name",
      "lazy-formatter": "",
      "formatter": _vm.formatter
    },
    model: {
      value: _vm.text2,
      callback: function callback($$v) {
        _vm.text2 = $$v;
      },
      expression: "text2"
    }
  })], 1), _c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('strong', [_vm._v("Value:")]), _vm._v(" " + _vm._s(_vm.text2) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }