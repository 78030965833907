var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Sizing Options"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeSize) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Set heights using the ")]), _c('code', [_vm._v("size")]), _c('span', [_vm._v(" prop to ")]), _c('code', [_vm._v("sm")]), _c('span', [_vm._v(" or ")]), _c('code', [_vm._v("lg")]), _c('span', [_vm._v(" for small or large respectively.")])]), _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Large",
      "label-for": "largeInput"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "largeInput",
      "size": "lg",
      "placeholder": "Large Input"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Default",
      "label-for": "defaultLabel"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "defaultLabel",
      "placeholder": "Normal Input"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Small",
      "label-for": "smallInput"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "smallInput",
      "size": "sm",
      "placeholder": "Small Input"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }